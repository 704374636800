import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/lb/layout";

const IndexPage = () => {

	var seo = { metaTitle:'Portfoliosait | Dapit', metaDescription:"All mengen Projeten obgelescht mat einer Beschreiwung an Biller.", shareImage:"/images/LandingPage-Dapit.png"};

	return (
		<Layout pageen="/portfolio" pagelb="/portfolio" seo={seo}>
			<section className="container-fluid block mt-5 mb-5">
				<div className="row mt-4">
					<div className="col-12 col-lg-5 title">
						<p className="date">20 Aprël 2021</p>
						<h2>Nocturnal E-Sports A.S.B.L</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Websait</h3>
							<p>
								Nocturnal E-Sports nei Websäit ass eng Plattform wou se hir Neiegkeeten posten a wou Dir detailléiert Informatiounen iwwer hir Teams an de verschiddenen E-Sporte fannt. Iwwer dës Equipen fannt Dir detailléiert Informatioun iwwer den Tournoi mat hirem Resultat an deem se konkurréieren.
							</p>
							<p>
								D'Säit féiert och hir Memberen vir a wat se maachen.
							</p>
							<ul>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fas fa-globe"></i>
									</a>
								</li>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fab fa-facebook-square"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 image">
						<img src="/images/Nocturnal-Website.jpg" alt="Image, Nocturnal E-sports Screenshot of the website's landing page."/>
						<div className="caption">
							<div className="box">
								<span className="header">Nocturnal E-sport</span><br/>
								'Startsait'
							</div>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content">
						<h3>Websait</h3>
						<p>
							Nocturnal E-Sports nei Websäit ass eng Plattform wou se hir Neiegkeeten posten a wou Dir detailléiert Informatiounen iwwer hir Teams an de verschiddenen E-Sporte fannt. Iwwer dës Equipen fannt Dir detailléiert Informatioun iwwer den Tournoi mat hirem Resultat an deem se konkurréieren.
						</p>
						<p>
							D'Säit féiert och hir Memberen vir a wat se maachen.
						</p>
						<ul>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fas fa-globe"></i>
								</a>
							</li>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fab fa-facebook-square"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 order-lg-2 title">
						<p className="date">09 Dezember 2020</p>
						<h2>MX-5 NA in night</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Photografie</h3>
							<p>
								Shooting fir de Sacha mat sengem relative neie Schatz vun engem alen Mazda Mx-5 NA aus dem Joer 1996.
							</p>
							<ul>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 order-lg-1 image">
						<div className="carousel slide carousel-fade" id="mx5Carousel" data-bs-ride="carousel" data-bs-interval="false">
							<div className="carousel-inner">
								<div className="carousel-item active">
									<img src="/images/mx5-first-night-1.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
								<div className="carousel-item">
									<img src="/images/mx5-first-night-2-v2.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
								<div className="carousel-item">
									<img src="/images/mx5-first-night-5.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
								<div className="carousel-item">
									<img src="/images/mx5-first-night-6.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
							</div>
						</div>
						<div className="caption">
							<div className="box">
								<span className="header">MX-5 NA</span><br/>
								'Owes Photoshoot'
							</div>
						</div>
						<div className="carousel-control-box">
							<button className="carousel-control carousel-control-prev" type="button" data-bs-target="#mx5Carousel" data-bs-slide="prev">
								<i class="fas fa-caret-left"></i>
							</button>
							<button className="carousel-control carousel-control-next" type="button" data-bs-target="#mx5Carousel" data-bs-slide="next">
								<i class="fas fa-caret-right"></i>
							</button>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content mb-5">
						<h3>Photografie</h3>
						<p>
							Shooting fir de Sacha mat sengem relative neie Schatz vun engem alen Mazda Mx-5 NA aus dem Joer 1996.
						</p>
						<ul>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 title">
						<p className="date">03 November 2020</p>
						<h2>De Grenge Krees</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Illustration, Broschure</h3>
							<p>De Grénge Krees ass eng Initiativ vum Jugend Rout Kräiz fir méi Nohaltegkeet an hier Kolonien ze bréngen. Sie wollten en Akafsguide vir Liewensmëttel erstellen, mat deem d'Moniteuren sech iwwer wat se bei hierem Akaaf obpassen sollen informeieren kënnen.</p>
							<p>An Zesummenarbescht hun mir en schéie PDF mat hirem Wëssen iwwer d'Thema Nohaltegkeet a meng Illustratiounen kreéiert fir d'Opmierksamkeet ze fänken.</p>
							<p>No der éischter Iddi vum Guide a Form vum PDF, hu mir d'Iddi ausgebaut fir dëse Guide a Kombinatioun mat enger Websäit ze presentéieren.</p>
							<ul>
								<li>
									<a href="http://grengekrees.dapit.lu/assets/Akafsguide-DeGrengeKrees.pdf" target="_blank">
										<i class="fas fa-file-pdf"></i>
									</a>
								</li>
								<li>
									<a href="http://grengekrees.dapit.lu" target="_blank">
										<i className="fas fa-globe"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/jugendroutkraiz/" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
								<li>
									<a href="https://www.facebook.com/croixrougeluxembourgeoisejeunesse" target="_blank">
										<i className="fab fa-facebook-square"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 image">
						<img src="/images/degrengekrees.jpg" alt="Image, Screenshot of the broschure's website."/>
						<div className="caption">
							<div className="box">
								<span className="header">De Grenge Krees</span><br/>
								'Websait'
							</div>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content">
						<h3>Illustration, Broschure</h3>
						<p>De Grénge Krees ass eng Initiativ vum Jugend Rout Kräiz fir méi Nohaltegkeet an hier Kolonien ze bréngen. Sie wollten en Akafsguide vir Liewensmëttel erstellen, mat deem d'Moniteuren sech iwwer wat se bei hierem Akaaf obpassen sollen informeieren kënnen.</p>
						<p>An Zesummenarbescht hun mir en schéie PDF mat hirem Wëssen iwwer d'Thema Nohaltegkeet a meng Illustratiounen kreéiert fir d'Opmierksamkeet ze fänken.</p>
						<p>No der éischter Iddi vum Guide a Form vum PDF, hu mir d'Iddi ausgebaut fir dëse Guide a Kombinatioun mat enger Websäit ze presentéieren.</p>
						<ul>
							<li>
								<a href="http://grengekrees.dapit.lu/assets/Akafsguide-DeGrengeKrees.pdf" target="_blank">
									<i class="fas fa-file-pdf"></i>
								</a>
							</li>
							<li>
								<a href="http://grengekrees.dapit.lu" target="_blank">
									<i className="fas fa-globe"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/jugendroutkraiz/" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
							<li>
								<a href="https://www.facebook.com/croixrougeluxembourgeoisejeunesse" target="_blank">
									<i className="fab fa-facebook-square"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 order-lg-2 title">
						<p className="date">25 Oktober 2020</p>
						<h2>Fee Mädchen</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Photografie</h3>
							<p>
								E puer Fotoen fir e Frënd bearbescht. Hat mécht Cosplay an erstellt eege Pokémon Terrarien.
							</p>
							<ul>
								<li>
									<a href="https://www.etsy.com/de/shop/Hinymee" target="_blank">
										<i class="fas fa-shopping-basket"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/hinymee/" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/nomekop_terraria/" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 order-lg-1 image">
						<div className="carousel slide carousel-fade" id="fairymaidenCarousel" data-bs-ride="carousel" data-bs-interval="false">
							<div className="carousel-inner">
								<div className="carousel-item active">
									<img src="/images/Lisi-Maid-GreenHair-Color-InstaSquareSize.jpg" alt="Image, Hinymee as a maiden with green hair."/>
								</div>
								<div className="carousel-item">
									<img src="/images/Lisi-Elf-2-sw.jpg" alt="Image, Hinymee as an elf in black white."/>
								</div>
								<div className="carousel-item">
									<img src="/images/Lisi-Elf-1.jpg" alt="Image, Hinymee as an elf."/>
								</div>
							</div>
						</div>
						<div className="caption">
							<div className="box">
								<span className="header">Fee Mädchen</span><br/>
								'Casual Bearbeschtung'
							</div>
						</div>
						<div className="carousel-control-box">
							<button className="carousel-control carousel-control-prev" type="button" data-bs-target="#fairymaidenCarousel" data-bs-slide="prev">
								<i class="fas fa-caret-left"></i>
							</button>
							<button className="carousel-control carousel-control-next" type="button" data-bs-target="#fairymaidenCarousel" data-bs-slide="next">
								<i class="fas fa-caret-right"></i>
							</button>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content mb-5">
						<h3>Photografie</h3>
						<p>
							E puer Fotoen fir e Frënd bearbescht. Hat mécht Cosplay an erstellt eege Pokémon Terrarien.
						</p>
						<ul>
							<li>
								<a href="https://www.etsy.com/de/shop/Hinymee" target="_blank">
									<i class="fas fa-shopping-basket"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/hinymee/" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/nomekop_terraria/" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 title">
						<p className="date">30 Aprël 2019</p>
						<h2>Du d'Welt an ech</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Websait</h3>
							<p>Du d'Welt an Ech ass eng Persoun, déi Iech iwwer alles ronderëm eng méi gesond Welt informéiert. Hat verbréngt vill Zäit fir verschidde Weer ze fannen wéi Dir kleng Saachen an Ärem Liewen ännere kënnt fir e besseren Impakt op d'Welt ze maachen. </p>
							<ul>
								<li>
									<a href="http://www.dudweltanech.lu/" target="_blank">
										<i class="fas fa-globe"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/dudweltanech/" target="_blank">
										<i class="fab fa-facebook-square"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 image">
						<img src="/images/dudweltanech.jpg" alt="Image, Screenshot of Du d'Welt an ech's website."/>
						<div className="caption">
							<div className="box">
								<span className="header">Du d'Welt an Ech</span><br/>
								'Daat bescht'
							</div>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content">
						<h3>Websait</h3>
						<p>Du d'Welt an Ech ass eng Persoun, déi Iech iwwer alles ronderëm eng méi gesond Welt informéiert. Hat verbréngt vill Zäit fir verschidde Weer ze fannen wéi Dir kleng Saachen an Ärem Liewen ännere kënnt fir e besseren Impakt op d'Welt ze maachen. </p>
						<ul>
							<li>
								<a href="http://www.dudweltanech.lu/" target="_blank">
									<i class="fas fa-globe"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/dudweltanech/" target="_blank">
									<i class="fab fa-facebook-square"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 order-lg-2 title">
						<p className="date">12 Februar 2018</p>
						<h2>Cracking Mechanics</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Websait</h3>
							<p>Cracking Mechanics ass e Lifestyle Kanal fir eng speziell Zort Motorradfrënn. Dir gesitt se wéi se Rezensiounen maachen, Rennstreckendaag, Eventer an all Zort verréckt Saachen.</p>
							<ul>
								<li>
									<a href="http://crackingmechanics.com/" target="_blank">
										<i class="fas fa-globe"></i>
									</a>
								</li>
								<li>
									<a href="https://www.youtube.com/user/CrackingMechanics" target="_blank">
										<i class="fab fa-youtube"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/cramec_/" target="_blank">
										<i class="fab fa-instagram"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 order-lg-1 image">
						<img src="/images/crackingmechanics.jpg" alt="Image, Screenshot of cracking mechanics' website."/>
						<div className="caption">
							<div className="box">
								<span className="header">Cracking Mechanics</span><br/>
								'Websait'
							</div>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content">
						<h3>Websait</h3>
						<p>Cracking Mechanics ass e Lifestyle Kanal fir eng speziell Zort Motorradfrënn. Dir gesitt se wéi se Rezensiounen maachen, Rennstreckendaag, Eventer an all Zort verréckt Saachen.</p>
						<ul>
							<li>
								<a href="http://crackingmechanics.com/" target="_blank">
									<i class="fas fa-globe"></i>
								</a>
							</li>
							<li>
								<a href="https://www.youtube.com/user/CrackingMechanics" target="_blank">
									<i class="fab fa-youtube"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/cramec_/" target="_blank">
									<i class="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
